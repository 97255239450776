import React from "react";
import logo from "../../icons/personal-logo.png";

const Navbar = () => {
  const handleGithubLink = () => {
    window.location.href = "https://github.com/joevasquez344";
  };
  const handleLinkedInLink = () => {
    window.location.href = "https://linkedin.com/in/joe-vasquez-a40258155";
  };
  const handleResumeLink = () => {
    window.location.href =
      "https://drive.google.com/file/d/1vEnYot-R02rG9d9GFWQn8rHbuDc0sIHZ/view?usp=share_link";
  };

  return (
    <div className=" bg-white flex-1">
      <div className="flex w-full items-center justify-between px-3 py-3 mx-auto md:w-2/3 md:py-0 md:px-0">
        <div className=" flex items-center">
          <img
            className="w-14 h-14 relative md:right-7  lg:w-24 lg:h-24"
            src={logo}
            alt=""
          />

          <div className="flex flex-col lg:justify-center lg:flex-row font-mono">
            <div className="text-sm lg:text-2xl ml-2 lg:ml-0 text-gray-400">
              Joe Vasquez
            </div>
            <div className="text-sm lg:text-2xl ml-2 lg:ml-8 text-blue-400">
              Full Stack Web Developer
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-5 md:space-x-10">
            <div
              onClick={handleResumeLink}
              className="flex flex-col items-center cursor-pointer group"
            >
              <i class="fa-regular fa-file  md:fa-2x mb-1 group-hover:text-gray-500 transition ease-in-out duration-200"></i>
              <div className="hidden sm:flex group-hover:text-gray-500 text-sm lg:text-md xl:text-base transition ease-in-out duration-200 font-semibold">
                Resume
              </div>
            </div>
            <div
              onClick={handleGithubLink}
              className="flex flex-col items-center cursor-pointer group"
            >
              <i class="fa-brands fa-github  md:fa-2x mb-1 group-hover:text-gray-500 transition ease-in-out duration-200"></i>
              <div className="hidden sm:flex group-hover:text-gray-500 text-sm lg:text-md xl:text-base transition ease-in-out duration-200 font-semibold">
                Github
              </div>
            </div>
            <div
              onClick={handleLinkedInLink}
              className="flex flex-col items-center cursor-pointer group"
            >
              <i class="fa-brands fa-linkedin md:fa-2x text-blue-600  group-hover:text-blue-500 transition ease-in-out duration-200 mb-1"></i>
              <div className="hidden sm:flex group-hover:text-blue-500 text-sm lg:text-md xl:text-base transition ease-in-out duration-200 font-semibold">
                LinkedIn
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
