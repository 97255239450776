import React from "react";
import twitterDemoImage from "../twitter_demo.png";
import netflixDemoImage from "../netflix_demo.png";
import teamTreeDemoImage from "../team-tree-demo.png";

const Projects = () => {
  const handleTwitterProjectLink = () => {
    window.location.href = "https://twitter-clone-2-8cc40.web.app/";
  };
  const handleTwitterCodeLink = () => {
    window.location.href = "https://github.com/joevasquez344/twitter-clone-2";
  };

  const handleNetflixProjectLink = () => {
    window.location.href = "https://netflix-clone-a40d9.web.app";
  };
  const handleNetflixCodeLink = () => {
    window.location.href = "https://github.com/joevasquez344/netflix-clone";
  };
  const handleTeamTreeProjectLink = () => {
    window.location.href = "https://teamtree-joevasquez344.vercel.app";
  };
  const handleTeamTreeCodeLink = () => {
    window.location.href = "https://github.com/joevasquez344/teamtree";
  };
  return (
    <div className="md:w-2/3 md:mx-auto">
      <div className="text-white font-bold text-xl px-6 md:px-0 sm:text-3xl pt-12 mb-8">
        Projects
      </div>
      <div className="flex flex-col px-6 md:px-0 lg:flex-row md:space-x-10">
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <img src={teamTreeDemoImage} alt="" />

          <div className="bg-[#243447] relative p-5 text-white border-t-4 border-t-blue-400">
            <div className="bg-blue-400 absolute -top-3 w-full left-0 font-semibold flex items-center justify-center">
              Development in progress
            </div>
            <div className="font-bold  text-md md:text-lg text-center mb-2">
              Team Tree
            </div>
            <div className="text-center text-sm md:text-md font-semibold text-gray-400 mb-8">
              A full stack Next.js application.
            </div>
            <div className="flex items-center justify-center space-x-3 mb-2">
              <div
                onClick={handleTeamTreeProjectLink}
                className="flex items-center rounded-md sm:rounded-none space-x-3 bg-blue-400 px-2 py-1 md:px-4 md:py-2  md:hover:bg-white md:hover:text-blue-400 transition ease-in-out cursor-pointer duration-200"
              >
                <i class="fa-solid fa-eye"></i>{" "}
                <span className="text-sm sm:text-base">Live Demo</span>
              </div>
              <div
                onClick={handleTeamTreeCodeLink}
                className="flex items-center rounded-md sm:rounded-none space-x-3 bg-blue-400 px-2 py-1 md:px-4 md:py-2  hover:bg-white md:hover:text-blue-400 transition ease-in-out cursor-pointer duration-200"
              >
                <i class="fa-solid fa-code"></i>
                <span className="text-sm sm:text-base">Code</span>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <img src={twitterDemoImage} alt="" />
          <div className="bg-[#243447] p-6 text-white border-t-4 border-t-blue-400">
            <div className="font-bold text-md md:text-lg text-center mb-2">
              Twitter Clone
            </div>
            <div className="text-center text-sm md:text-md font-semibold text-gray-400 mb-8">
              A full stack React.js application
            </div>
            <div className="flex items-center justify-center space-x-3 mb-2">
              <div
                onClick={handleTwitterProjectLink}
                className="flex items-center rounded-md sm:rounded-none space-x-3 bg-blue-400 px-2 py-1 md:px-4 md:py-2  md:hover:bg-white md:hover:text-blue-400 transition ease-in-out cursor-pointer duration-200"
              >
                <i class="fa-solid fa-eye"></i>{" "}
                <span className="text-sm sm:text-base">Live Demo</span>
              </div>
              <div
                onClick={handleTwitterCodeLink}
                className="flex items-center rounded-md sm:rounded-none space-x-3 bg-blue-400 px-2 py-1 md:px-4 md:py-2  hover:bg-white md:hover:text-blue-400 transition ease-in-out cursor-pointer duration-200"
              >
                <i class="fa-solid fa-code"></i>
                <span className="text-sm sm:text-base">Code</span>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="flex justify-center items-center">
            <img className="" src={netflixDemoImage} alt="" />
          </div>
          <div className="bg-[#243447] p-6 text-white border-t-4 border-t-blue-400">
            <div className="font-bold text-md md:text-lg text-center mb-2">
              Netflix Clone
            </div>
            <div className="text-center text-sm md:text-md text-gray-400 font-semibold mb-8">
              A full stack React.js application
            </div>
            <div className="flex items-center justify-center space-x-3 mb-2">
              <div
                onClick={handleNetflixProjectLink}
                className="flex items-center rounded-md sm:rounded-none space-x-3 bg-blue-400 px-2 py-1 md:px-4 md:py-2  hover:bg-white hover:text-blue-400 transition ease-in-out cursor-pointer duration-200"
              >
                <i class="fa-solid fa-eye"></i>{" "}
                <span className="text-sm sm:text-base">Live Demo</span>
              </div>
              <div
                onClick={handleNetflixCodeLink}
                className="flex items-center rounded-md sm:rounded-none space-x-3 bg-blue-400 px-2 py-1 md:px-4 md:py-2  hover:bg-white hover:text-blue-400 transition ease-in-out cursor-pointer duration-200"
              >
                <i class="fa-solid fa-code"></i>
                <span className="text-sm sm:text-base">Code</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
