import React from "react";

import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import ProjectList from "../../components/ProjectList/ProjectList";

import postmanIcon from "../../icons/postman.png";
import expressIcon from "../../icons/express.png";
import mongodbIcon from "../../icons/mongodb.png";
import jqueryIcon from "../../icons/jquery.png";
import UciBadges from "../../components/UciBadges";
import Skills from "../../components/Skills";
import Projects from "../../components/Projects";

const Landing = () => {
  return (
    <div className="">
      <Navbar />
      <div className="bg-[#141d26] relative py-12">
        <Skills />
        <Projects />

        <UciBadges />
      </div>
      {/* <Navbar /> */}
      {/* <Hero /> */}
      {/* <ProjectList /> */}
      {/* <div className="">
        <div className="bg-blue-400 flex mx-auto p-6 text-white">
          <div className="bg-blue-400 w-1/3 h-64 flex justify-center items-center">
            <i class="fa-brands fa-twitter fa-4x text-white"></i>
          </div>
          <div className="w-2/3 bg-blue-400">
            <div className="text-xl font-bold mb-3">Twitter Clone</div>
            <div className="mb-3">Full stack application hooked up to Firebase CLI that implements the bare bones of Twitter with standard CRUD operations.</div>
            <div className="font-semibold mb-1">Integrations</div>
            <div>Firebase Authentication</div>
            <div>React Redux</div>
            <div>Tailwind CSS</div>
          </div>
        </div>
        <div className="flex bg-black mx-auto p-6 text-white">
          <div className="bg-black w-1/3 h-64 flex justify-center items-center">
            <img
              className="w-1/2"
              src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c529.png"
              alt=""
            />
          </div>
          <div className="w-2/3 bg-black">
            <div className="text-xl font-bold">Netflix Clone</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Landing;
