import React from "react";
import postmanIcon from "../icons/postman.png";
import expressIcon from "../icons/express.png";
import mongodbIcon from "../icons/mongodb.png";
import jqueryIcon from "../icons/jquery.png";

const Skills = () => {
  return (
    <div className="flex flex-col sm:flex-row md:w-2/3 space-x-0 sm:space-x-10  md:space-x-32  px-5 md:px-0  mx-auto ">
      <div className="mb-10 md:mb-0">
        <div className="mb-5 text-gray-400 font-bold">Front-End Skills</div>
        <div className="max-[280px]:flex max-[280px]:space-x-5">
          <div className="flex max-[280px]:flex-col max-[280px]:items-start max-[280px]:space-x-0  max-[280px]:space-y-2  space-x-10 mb-5">
            <div className="flex flex-col ">
              <i class="fa-brands fa-html5 fa-3x mb-1 text-blue-600"></i>
              <div className="text-gray-400 text-sm">HTML</div>
            </div>
            <div className="flex flex-col text-center">
              <i class="fa-brands fa-css3-alt fa-3x mb-1 text-orange-500 "></i>
              <div className="text-gray-400 text-sm">CSS</div>
            </div>
            <div className="flex flex-col text-center">
              <i class="fa-brands fa-square-js fa-3x mb-1 text-yellow-400"></i>
              <div className="text-gray-400 text-sm ">Javascript</div>
            </div>
            <div className="flex flex-col text-center">
              <img className="h-12 w-12 mb-1" src={jqueryIcon} alt="" />

              <div className="text-gray-400 text-sm ">JQuery</div>
            </div>
          </div>
          <div className="flex space-x-10 max-[280px]:flex-col max-[280px]:items-center max-[280px]:space-x-0 max-[280px]:space-y-2">
            <div className="flex flex-col text-center">
              <i class="fa-brands fa-bootstrap fa-3x mb-1 text-purple-700"></i>
              <div className="text-gray-400 text-sm ">Bootstrap</div>
            </div>
            <div className="flex flex-col text-center">
              <i class="fa-brands fa-sass fa-3x mb-1 text-pink-500"></i>
              <div className="text-gray-400 text-sm ">Sass</div>
            </div>
            <div className="flex flex-col text-center">
              <i class="fa-brands fa-react fa-3x mb-1 text-blue-400"></i>
              <div className="text-gray-400 text-sm ">React</div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="mb-5 text-gray-400 font-bold">Back-End Skills</div>
        <div className="max-[280px]:flex-col max-[280px]:items-start max-[280px]:space-x-0 flex items-center space-x-10 sm:space-x-4 md:justify-center md:flex-col md:space-x-0">
          <div className="max-[280px]:mb-5 flex items-center space-x-10  sm:space-x-4 md:mb-5 lg:space-x-10">
            <div className="flex flex-col items-center">
              <i class="fa-brands fa-node fa-3x mb-1 text-green-600"></i>
              <div className="text-gray-400 text-sm">Node</div>
            </div>
            <div className="flex flex-col items-center">
              <img className="h-12 w-12 mb-1" src={postmanIcon} alt="" />
              <div className="text-gray-400 text-sm">Postman</div>
            </div>
          </div>

          <div className="flex items-center space-x-10 sm:space-x-4 lg:space-x-10">
            <div className="flex flex-col items-center">
              <img className="h-12 w-12 mb-1" src={expressIcon} alt="" />
              <div className="text-gray-400 text-sm">Express</div>
            </div>
            <div className="flex flex-col items-center">
              <img className="h-12 w-12 mb-1" src={mongodbIcon} alt="" />
              <div className="text-gray-400 text-sm">MongoDB</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
