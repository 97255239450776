import React from "react";
import htmlCssBadge from "../icons/html-css.png";
import jsBadge from "../icons/javascript-and-jquery.png";
import reactBadge from "../icons/react-js.png";
import nodeExpressBadge from "../icons/node-js-and-express.png";
import dbBadge from "../icons/sql-and-nosql-databases.png";

const UciBadges = () => {
  return (
    <div className="hidden lg:flex flex-col top-10 lg:left-1 xl:left-16 absolute items-center justify-evenly mb-6 mx-auto">
      <img src={htmlCssBadge} alt="" />
      <img src={jsBadge} alt="" />
      <img src={reactBadge} alt="" />
      <img src={nodeExpressBadge} alt="" />
      <img src={dbBadge} alt="" />
    </div>
  );
};

export default UciBadges;
